import { createRouter, createWebHistory } from 'vue-router';
import MonthView from "@/views/MonthView";
import ServicesView from "@/views/ServicesView";
import HomeView from "@/views/HomeView";
import Error404View from "@/views/Error404View";
import AddServicesView from "@/views/AddServicesView";
import ErrorView from "@/views/ErrorView";
import LoginView from "@/views/LoginView";
import MainView from "@/views/MainView";
import { useAuthStore } from "@/stores/auth";
import Cookies from "js-cookie";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/',
    name: 'main',
    component: MainView,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: HomeView,
        meta: { requiresAuth: true }
      },
      {
        path: '/calendar',
        name: 'calendar',
        component: MonthView,
        meta: { requiresAuth: true }
      },
      {
        path: '/services',
        name: 'services',
        component: ServicesView,
        meta: { requiresAuth: true }
      },
      {
        path: '/service/add/:service:type',
        name: 'service-add',
        component: AddServicesView,
        props: true,
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '/error:error',
    name: "error",
    component: ErrorView,
    props: true,
    meta: { guest: true }
  },
  {
    path: '/:catchAll(.*)*',
    name: "page-not-found",
    component: Error404View,
    meta: { guest: true }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const store = useAuthStore();
  let token = Cookies.get('access_token');
  if (!to.matched.some((record) => record.meta.requiresAuth) || store.getUser || token) {
    next();
  } else {
    next("/login");
  }
});

export default router;

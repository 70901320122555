import { defineStore } from 'pinia'
import axios from "axios"

export const useAuthStore = defineStore("auth",{
    state: () => ({
        user: null
    }),
    getters: {
        getUser(state){
            return state.user;
        }
    },
    actions: {
        async register(username, password) {
            try {
                const data = await axios.post(process.env.VUE_APP_API_URL + '/auth/register', {username, password});
                this.user = data.data;
                this.$router.push({name: 'dashboard'});
            }
            catch (error) {
                this.$router.push({name: 'error', params:{error:error}});
            }
        },
        async login(username, password) {
            try {
                const data = await axios.post(process.env.VUE_APP_API_URL + '/auth/login', {username, password});
                this.user = data.data;
                this.$router.push({name: 'dashboard'});
            }
            catch (error) {
                this.$router.push({name: 'error', params:{error:error}});
            }
        },
        async logout() {
            try {
                const data = await axios.get(process.env.VUE_APP_API_URL + '/auth/logout');
                if(data.data) {
                    this.user = null;
                    this.$router.push({name: 'login'});
                }
            }
            catch (error) {
                this.$router.push({name: 'error', params:{error:error}});
            }
        }
    },
});

<template>
  <topbar-view/>
  <div style="display: flex; height: 100%; position: fixed; width: 100%; top: 59px;">
    <div id="elSideMenu" style="display: grid; border-right: solid; border-right-color: #e6e6ef; border-right-width: 1px;">
      <nav style="padding: 0;">
        <Menu :model="navItems" style="border: none; width: 10em;"/>
      </nav>
    </div>
    <div id="elMainPane" style="margin: 1em; width: 100%; height: 100%">
<!--        <div>
        <Breadcrumb :home="home" :model="breadcrumbItems" aria-label="breadcrumb" exact="true">
          <template #item="{item}">
            <router-link :to="item.to" custom v-slot="{href, route, navigate, isActive, isExactActive}">
              <a :href="href" @click="navigate" :class="{'active-link': isActive, 'active-link-exact': isExactActive}">{{route.fullPath}}</a>
            </router-link>
          </template>
        </Breadcrumb>
      </div>-->
      <div>
        <ScrollPanel style="width: 100%; height: 40em">
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </ScrollPanel>
      </div>
    </div>
  </div>
  <div>
    <Sidebar v-model:visible="visibleLeft"
             :modal=false
             :blockScroll=false
             :dismissable=false
             :autoZIndex=false
             closeIcon="pi pi-angle-double-left">
      <h3>Left Sidebar</h3>
    </Sidebar>
  </div>

</template>

<script>

import TopbarView from "@/views/TopbarView";
export default {
  name: 'MainView',
  components: {
    TopbarView
  },
  data() {
    return {
      home: {
        icon: 'pi pi-home',
        to: '/',
      },
      expandedKeys: {},
      navItems: [{
        key: '0',
        label: 'Dashboard',
        icon: 'pi pi-fw pi-home',
        to: '/',
/*        items: [{
          key: '0_0',
          label: 'New',
          icon: 'pi pi-fw pi-plus',
          items: [{
            key: '0_0_0',
            label: 'Bookmark',
            icon: 'pi pi-fw pi-bookmark'
          },
            {
              key: '0_0_1',
              label: 'Video',
              icon: 'pi pi-fw pi-video'
            }
          ]
        },
          {
            key: '0_1',
            label: 'Delete',
            icon: 'pi pi-fw pi-trash'
          },
          {
            key: '0_2',
            label: 'Export',
            icon: 'pi pi-fw pi-external-link'
          }
        ]*/
      },
        {
          key: '1',
          label: 'Calendar',
          icon: 'pi pi-fw pi-calendar',
          to: '/calendar',
/*          items: [{
            key: '1_0',
            label: 'Left',
            icon: 'pi pi-fw pi-align-left'
          },
            {
              key: '1_1',
              label: 'Right',
              icon: 'pi pi-fw pi-align-right'
            },
            {
              key: '1_2',
              label: 'Center',
              icon: 'pi pi-fw pi-align-center'
            },
            {
              key: '1_3',
              label: 'Justify',
              icon: 'pi pi-fw pi-align-justify'
            }
          ]*/
        },
        /*{
          key: '2',
          label: 'Users',
          icon: 'pi pi-fw pi-user',
          items: [{
            key: '2_0',
            label: 'New',
            icon: 'pi pi-fw pi-user-plus',

          },
            {
              key: '2_1',
              label: 'Delete',
              icon: 'pi pi-fw pi-user-minus',
            },
            {
              key: '2_2',
              label: 'Search',
              icon: 'pi pi-fw pi-users',
              items: [{
                key: '2_2_0',
                label: 'Filter',
                icon: 'pi pi-fw pi-filter',
                items: [{
                  key: '2_2_0_0',
                  label: 'Print',
                  icon: 'pi pi-fw pi-print'
                }]
              },
                {
                  key: '2_2_1',
                  icon: 'pi pi-fw pi-bars',
                  label: 'List'
                }
              ]
            }
          ]
        },*/
        {
          key: '3',
          label: 'Services',
          icon: 'pi pi-fw pi-calendar',
          to: '/services',
        },
        /*{
          key: '4',
          label: 'Events',
          icon: 'pi pi-fw pi-calendar',
          items: [{
            key: '4_0',
            label: 'Edit',
            icon: 'pi pi-fw pi-pencil',
            items: [{
              key: '4_0_0',
              label: 'Save',
              icon: 'pi pi-fw pi-calendar-plus'
            },
              {
                key: '4_0_0',
                label: 'Delete',
                icon: 'pi pi-fw pi-calendar-minus'
              }
            ]
          },
            {
              key: '4_1',
              label: 'Archieve',
              icon: 'pi pi-fw pi-calendar-times',
              items: [{
                key: '4_1_0',
                label: 'Remove',
                icon: 'pi pi-fw pi-calendar-minus'
              }]
            }
          ]
        }*/
        ],
      breadcrumbItems: [
      ],
      visibleLeft: false,
      items: [
        {
          label: 'Update',
          icon: 'pi pi-refresh'
        },
        {
          label: 'Delete',
          icon: 'pi pi-times'
        },
        {
          label: 'Vue Website',
          icon: 'pi pi-external-link',
          command: () => {
            window.location.href = 'https://vuejs.org/'
          }
        },
        {   label: 'Upload',
          icon: 'pi pi-upload',
          command: () => {
            window.location.hash = "/fileupload"
          }
        }
      ]
    }
  },
  methods: {
    expandAll() {
      for (let node of this.items) {
        this.expandNode(node);
      }

      this.expandedKeys = {
        ...this.expandedKeys
      };
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    expandNode(node) {
      if (node.items && node.items.length) {
        this.expandedKeys[node.key] = true;

        for (let child of node.items) {
          this.expandNode(child);
        }
      }
    }
  },
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

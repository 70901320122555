<template>
  <div>
    <div class="flex align-items-start border-bottom-1 surface-border surface-overlay w-full">
      <p class="text-5xl">📊 Dashboard</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  components: {
  },
  data() {
    return {
      environment: process.env.VUE_APP_TITLE,
    };
  },
}
</script>

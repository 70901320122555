import { defineStore } from 'pinia'
import axios from "axios"

export const useServiceStore = defineStore("service",{
    state: () => ({
        services: [],
    }),
    getters: {
        getServices(state){
            return state.services;
        }
    },
    actions: {
        async fetchServices() {
            try {
                const data = await axios.get(process.env.VUE_APP_API_URL + '/service/all');
                this.services = data.data;
            }
            catch (error) {
                this.$router.push({name: 'error', params:{error:error}});
            }
        }
    },
});

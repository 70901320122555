<template>
  <Button type="button" class="p-button-rounded p-button-secondary p-button-outlined p-button-raised button" @click="addService" :disabled="status == '0'" >
    <div class="grid">
      <img alt="logo" :src="filename" class="image"/>
      <span>{{ title }}</span>
    </div>
  </Button>
</template>

<script>
export default {
  name: 'ServiceButton',
  props: {
    id: String,
    name: String,
    title: String,
    type: String,
    filename: String,
    status: String
  },
  methods: {
    addService(){
      if(this.type==='oauth'){
        let url = process.env.VUE_APP_API_URL + '/service/connect?type=' + this.type + '&name=' +this.name;
        window.open(url, '_blank', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600');
      }
      this.$router.push({name: 'service-add', params:{service:this.title, type: this.type}});
    }
  }
}
</script>

<style scoped>
.image {
  width: 2em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}
.button {
  border-radius: 1rem;
  min-width: 7em;
  min-height: 7em;
  display: inline;
}
.button:hover{
  background-color: rgba(122, 203, 175, 0.77) !important;
}
.grid {
display: grid;
}
</style>

<template>
  <div>
    <div class="flex align-items-start border-bottom-1 surface-border surface-overlay w-full">
      <p class="text-5xl">🪢 Add {{service}} : {{type}}</p>
    </div>
    <div v-if="type === 'password'" style="display: block;">
      <div class="card" style="width: 50em">
        <Steps :model="items" :readonly="true" aria-label="Form Steps" />
      </div>
      <div style="display: flex; margin: 1em; gap: 1em;">
        <p class="text-3xl">Username</p>
        <InputText type="text" v-model="username" style="width: 100%"/>
      </div>
      <div style="display: flex; margin: 1em; gap: 1em;">
        <p class="text-3xl">Password</p>
        <Password v-model="password" toggleMask :feedback="false" style="width: 100%" panelStyle="width: 100%" inputStyle="width: 100%"/>
      </div>
      <div style="display: flex; margin: 1em; gap: 1em; justify-content: end">
        <Button label="Submit" />
      </div>
    </div>
    <div v-else-if="type === 'oauth'">
      <button @click="openWindow(process.env.VUE_APP_API_URL + '/service/connect')">Open</button>
    </div>
    <div v-else-if="type === 'token'" style="display: block;">
      <div style="display: flex; margin: 1em; gap: 1em;">
        <p class="text-3xl">Token</p>
        <Password v-model="token" toggleMask :feedback="false" style="width: 100%" panelStyle="width: 100%" inputStyle="width: 100%"/>
      </div>
      <div style="display: flex; margin: 1em; gap: 1em; justify-content: end">
        <Button label="Submit" />
      </div>
    </div>
    <div v-else>
      None
    </div>
  </div>
</template>

<script>

export default {
  name: 'AddServicesView',
  props: {
    type: String,
    service: String
  },
  components: {
  },
  data() {
    return {
      items: [{
        label: 'Personal',
        to: '/service/add'
      },
        {
          label: 'Seat',
          to: '/seat'
        },
        {
          label: 'Payment',
          to: '/payment'
        },
        {
          label: 'Confirmation',
          to: '/confirmation'
        }],
      formObject: {},
      token: '',
      username: '',
      password: '',
    }
  },
  methods: {
    nextPage(event) {
      for (let field in event.formData) {
        this.formObject[field] = event.formData[field];
      }

      this.$router.push(this.items[event.pageIndex + 1].to);
    },
    prevPage(event) {
      this.$router.push(this.items[event.pageIndex - 1].to);
    },
    complete() {
      this.$toast.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.'});
    },
    openWindow(url) {
      window.open(url, '_blank', 'scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600');
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep(b) {
  display: block;
}

::v-deep(.p-card-body) {
  padding: 2rem;
}

::v-deep(.p-password input) {
  width: 15rem
}
</style>

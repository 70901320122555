<template>
  <div>
    <h1 style="color: red;">Error</h1>
    <p style="color: red;">{{error}}</p>
  </div>
</template>

<script>

export default {
  name: 'ErrorView',
  props: {
    error: String,
  },
}
</script>

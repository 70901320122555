<template>
  <Toast position="top-right" group="tr" />
  <Toast position="top-left" group="tl" />
  <Toast position="bottom-left" group="bl" />
  <Toast position="bottom-right" group="br" />
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script>
export default {
  components: {}
}
</script>

<template>
  <div>
  <div class="flex align-items-start border-bottom-1 surface-border surface-overlay w-full">
    <p class="text-5xl">📆 Plan your days</p>
  </div>
  <div class="pt-3">
    <FullCalendar :options="options" ref="fullCalendar"/>
  </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import EventService from '../service/EventService';
import { useEventStore } from "../stores/events";

export default {
  name: "MonthView",
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      options: {
        plugins:[dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialDate : new Date(),//'2023-02-01',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
        editable: true,
        selectable:true,
        selectMirror: true,
        dayMaxEvents: true,
        initialView: 'dayGridMonth',
        events: [
          { title: 'event 1', date: '2023-02-01' },
          { title: 'event 2', date: '2023-02-02' }
        ]
      },
      store: null,
    };
  },
  eventService: null,
  computed: {
    events() {
      return this.store.events;
    }
  },
  created() {
    this.eventService = new EventService();
    this.store = useEventStore();
    this.store.fetchEvents();
  },
  mounted() {
    //this.eventService.getEvents().then(data => this.events = data);
    this.options.events = this.store.events;
    this.options.initialDate = new Date();
  },
}
</script>

<style scoped>
  h1 {
    font-weight: 500;
    font-size: 2.6rem;
    top: -10px;
  }
  h3 {
    font-size: 1.2rem;
  }
  button {
    margin-top: 20px;
  }
</style>

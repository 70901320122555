<template>
  <p class="neonText text-3xl">{{date}} - {{time}}</p>
</template>

<script>
export default {
  name: 'DateTime',
  data() {
    return {
      interval: null,
      time: null,
      date: null,
      week: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    }
  },
  methods: {
    zeroPadding(num, digit) {
      var zero = '';
      for (var i = 0; i < digit; i++) {
        zero += '0';
      }
      return (zero + num).slice(-digit);
    },
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  created() {
    this.interval = setInterval(() => {
      var cd = new Date();
      this.time = this.zeroPadding(cd.getHours(), 2) + ':' + this.zeroPadding(cd.getMinutes(), 2) + ':' + this.zeroPadding(cd.getSeconds(), 2);
      this.date = this.zeroPadding(cd.getDate(), 2) + '/' + this.zeroPadding(cd.getMonth()+1, 2) + '/' + this.zeroPadding(cd.getFullYear(), 4) + ' ' + this.week[cd.getDay()];
    }, 1000)
  }
}
</script>

<style scoped>
.neonText {
  color: #efebeb;
  text-shadow:
      0 0 7px #4c636e,
      0 0 10px #4c636e,
      0 0 21px #4c636e,
      0 0 42px #88b0c4,
      0 0 82px #88b0c4,
      0 0 92px #88b0c4,
      0 0 102px #88b0c4
}
</style>

import { createApp, markRaw } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';

import './assets/main.css'
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import router from './router'
import InputText from "primevue/inputtext";
import Toolbar from "primevue/toolbar";
import Sidebar from "primevue/sidebar";
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Breadcrumb from "primevue/breadcrumb";
import PanelMenu from "primevue/panelmenu";
import Menu from "primevue/menu";
import Steps from "primevue/steps";
import ScrollPanel from "primevue/scrollpanel";
import Password from 'primevue/password';
import axios from "axios";


const app = createApp(App);
const pinia = createPinia();
pinia.use(({ store }) => {
    store.$router = markRaw(router)
});

axios.defaults.withCredentials = true;

app.use(PrimeVue, {ripple: true});
app.use(pinia);
app.use(ToastService);
app.use(router);

app.component('Button', Button);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('Sidebar', Sidebar);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('InputText', InputText);
app.component('Breadcrumb', Breadcrumb);
app.component('PanelMenu', PanelMenu);
app.component('Menu', Menu);
app.component('Steps', Steps);
app.component('Password', Password);
app.component('ScrollPanel', ScrollPanel);


app.mount('#app')

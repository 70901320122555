<template>
  <div>
    <div class="flex align-items-start border-bottom-1 surface-border surface-overlay w-full">
      <p class="text-5xl">🤝 Add service provider</p>
    </div>
    <div class="pt-3">
      <ServiceButton style="margin: 5px" v-for="service in services"
                     :key="service._id"
                     :id="service._id"
                     :name="service.name"
                     :title="service.displayName"
                     :filename="service.logoFilename"
                     :type="service.connectionType"
                     :status="service.status"
      />
    </div>
  </div>
</template>

<script>
import ServiceButton from "@/components/ServiceButton";
import { useServiceStore } from "@/stores/services";

export default {
  name: 'ServicesView',
  components: {
    ServiceButton
  },
  computed: {
    services() {
      return this.store.services;
    }
  },
  data () {
    return {
      store: null,
    }
  },
  created() {
    this.store = useServiceStore();
    this.store.fetchServices();
  },
}
</script>

<template>
  <div style="position: fixed; top: 25%;  left: 50%; transform: translate(-50%, -50%);">
    <h1>Welcome 👋</h1>
  </div>
  <div v-if="isLogin" class="middle">
    <div class="flex flex-column gap-2">
      <label for="username">Username</label>
      <InputText id="username" v-model="username" aria-describedby="username-help" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="password">Password</label>
      <Password id="password" v-model="password" toggleMask :feedback="false" input-style="width: 100%"/>
    </div>
    <Button label="Submit" style="margin-top: 1em;" @click="login"/>
    <Button label="Register" class="p-button-link" @click="switchLoginRegister"/>
  </div>
  <div v-else class="middle">
    <div class="flex flex-column gap-2">
      <label for="newUsername">Username</label>
      <InputText id="newUsername" v-model="username" aria-describedby="username-help" />
    </div>
    <div class="flex flex-column gap-2">
      <label for="newPassword">Password</label>
      <Password id="newPassword" v-model="password" toggleMask :feedback="false" input-style="width: 100%"/>
    </div>
    <Button label="Submit" style="margin-top: 1em;" @click="register"/>
    <Button label="Login" class="p-button-link" @click="switchLoginRegister"/>
  </div>


</template>

<script>

import {useAuthStore} from "@/stores/auth";

export default {
  name: 'LoginView',
  components: {

  },
  data() {
    return {
      isLogin: true,
      store: null,
      username: '',
      password: ''
    }
  },
  methods: {
    switchLoginRegister(){
      this.isLogin = !this.isLogin;
    },
    login() {
      this.store.login(this.username, this.password);
    },
    register() {
      this.store.register(this.username, this.password);
    }
  },
  created() {
    this.store = useAuthStore();
  },
}
</script>
<style scoped>
.middle {
  position: fixed;
  display: grid;
  row-gap: 1.5em;
  width: 350px;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.19);
  border-radius: 1rem;
  padding: 2em 2em 3.5em 2em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

import { defineStore } from 'pinia'
import axios from "axios"

export const useEventStore = defineStore("event",{
    state: () => ({
        events: [],
    }),
    getters: {
        getEvents(state){
            return state.events;
        }
    },
    actions: {
        async fetchEvents() {
            try {
                const data = await axios.get(process.env.VUE_APP_API_URL + '/event/all');
                this.events = data.data;
            }
            catch (error) {
                this.$router.push({name: 'error', params:{error:error}});
            }
        }
    },
});

<template>
  <div style="max-height: 45px;min-height: 45px;">
    <Toolbar style="padding: 0.5rem; border-bottom: solid; border-bottom-color: #e6e6ef; border-bottom-width: 1px;">//box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);">
      <template #start>
        <img alt="logo" src="../assets/logo-no-background.png" style="height: auto; width: 4em; cursor: pointer" class="ml-5" @click="this.$router.push('/')">
      </template>
      <template #center>
        <date-time />
      </template>
      <template #end>
        <div>
          <Button icon="pi pi-sync" class="user-area-button p-button-rounded p-button-secondary" />
          <Button icon="pi pi-megaphone" class="user-area-button p-button-rounded p-button-secondary" @click="showSuccess"/>
          <Button icon="pi pi-power-off" class="user-area-button p-button-rounded p-button-secondary" @click="logout"/>
        </div>
      </template>
    </Toolbar>
  </div>
</template>

<script>

import DateTime from "@/components/DateTime";
import {useAuthStore} from "@/stores/auth";

export default {
  name: 'TopbarView',
  components: {
    DateTime
  },
  data() {
    return {
      store: null
    }
  },
  methods: {
    showSuccess() {
      this.$toast.add({severity: 'error', summary: 'Logged out!', detail: 'Log out clicked', group: 'br', life: 3000});
    },
    logout() {
      this.store.logout();
    },
  },
  created() {
    this.store = useAuthStore();
  },
}
</script>
<style scoped>
.user-area-button {
  margin: 0em 0em 0em 0.4em;
}
</style>
